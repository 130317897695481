<template>
  <div class="process">
    <div class="list">
      <dl v-for="(item, index) of list" :key="index">
        <dt>
          <el-image
            class="avatar"
            :src="item.operateUserAvatar || $defaultAvatarUrl"
          ></el-image>
          <template v-if="item.auditReportReqVo">
            <div
              class="icon icon-tick"
              v-if="item.auditReportReqVo.reportState === 1"
            >
              <svg-icon icon-class="tick"></svg-icon>
            </div>

            <div
              class="icon icon-close"
              v-if="item.auditReportReqVo.reportState === 3"
            >
              <svg-icon icon-class="close"></svg-icon>
            </div>
          </template>
          <div class="icon icon-tick" v-else-if="item.isComplete">
            <svg-icon icon-class="tick"></svg-icon>
          </div>
          <div class="icon icon-more-lite" v-else>
            <svg-icon icon-class="more-lite"></svg-icon>
          </div>
        </dt>
        <dd>
          <div class="title">
            <div>
              {{ item.currentNodeStr }}
              <text
                class="red"
                v-if="item.currentNode === 'REPORT_AUDIT' && !item.isComplete"
                >{{ item.operateLabel }}</text
              >
            </div>
            <div class="time">
              {{ item.showDate }}
              <el-popover
                v-if="item.currentNode === 'REPORT_SUBMIT'"
                v-model="reportTimeUpdaterVisible"
                placement="left"
                title="修改报备时间"
                width="250"
                trigger="click"
              >
                <el-date-picker
                  v-model="item.operateDate"
                  type="datetime"
                  placeholder="选择日期时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  @change="updateReportTime"
                >
                </el-date-picker>
                <el-button
                  style="padding: 0 0 0 5px;"
                  slot="reference"
                  type="text"
                  size="small"
                  >修改</el-button
                >
              </el-popover>
            </div>
          </div>
          <template
            v-if="item.currentNode !== 'REPORT_AUDIT' || item.isComplete"
          >
            <p v-if="item.operateUser && item.operateLabel">
              {{ item.operateUser }}，{{ item.operateLabel }}
            </p>
            <!-- 审核失败的理由是黑色 其他是灰色 -->
            <p
              v-if="item.extInfo"
              :style="{
                color:
                  item.auditReportReqVo &&
                  item.auditReportReqVo.reportState === 3
                    ? '#333'
                    : '#aaa'
              }"
            >
              {{ item.extInfo }}
            </p>
          </template>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { updateReportTime } from "@/api/distribution";
export default {
  props: {
    reportId: {
      type: Number,
      default: null
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      reportTimeUpdaterVisible: false,
      list: []
    };
  },
  watch: {
    data: {
      immediate: true,
      handler(val) {
        this.list = val || [];
      }
    }
  },
  methods: {
    async updateReportTime(value) {
      this.reportTimeUpdaterVisible = false;
      const res = await updateReportTime({ id: this.reportId, date: value });
      if (res) {
        this.$emit("change");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.process {
  .list {
    padding: 30px 0;
  }
  dl {
    display: flex;
    position: relative;
    padding-bottom: 50px;
    &:after {
      display: block;
      content: "";
      width: 3px;
      background-color: #f2f2f2;
      position: absolute;
      bottom: 10px;
      top: 50px;
      left: 18px;
    }
    &:last-child {
      padding-bottom: 0;
      &:after {
        display: none;
      }
    }
  }
  dt {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    position: relative;
    border-radius: 50%;
    .avatar {
      width: 40px;
      height: 40px;
    }
    .icon {
      position: absolute;
      right: 0px;
      bottom: -3px;
      font-size: 12px;
      width: 15px;
      height: 15px;
      line-height: 15px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      &-tick {
        background: #52c41a;
      }
      &-more-lite {
        background: #fa8c16;
      }
      &-close {
        background: #f5222d;
      }
      svg {
        font-size: 8px;
      }
    }
  }
  dd {
    flex: 1;
    @include flex-yc2;
    .title {
      font-size: 14px;
      color: #333;
      line-height: 100%;
      display: flex;
      justify-content: space-between;
      .time {
        font-size: 12px;
        color: #aaa;
      }
    }
    p {
      font-size: 14px;
      color: #aaa;
      line-height: 100%;
      margin-top: 8px;
      &.red {
        color: $primary;
      }
    }
  }
}
</style>
