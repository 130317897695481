<template>
  <div>
    <el-drawer
      v-bind="$attrs"
      v-on="$listeners"
      :with-header="false"
      size="400"
    >
      <div class="report-detail">
        <div class="action-bar">
          <div class="report-code">NO.{{ detail.reportCode }}</div>
          <div
            v-if="detail.reportState !== undefined"
            class="status"
            :style="{
              color:
                statusMap[detail.reportState] &&
                statusMap[detail.reportState].color
            }"
          >
            <svg-icon
              :icon-class="statusMap[detail.reportState].iconName"
            ></svg-icon
            ><span style="padding-left: 5px;">{{ detail.reportStateStr }}</span>
          </div>
          <div class="close-drawer-btn" @click="closeDrawer">
            <svg-icon icon-class="close"></svg-icon>
          </div>
        </div>
        <div class="content">
          <div class="info-cell">
            <div class="col">
              <div class="label">报备项目</div>
              <div class="value">{{ detail.estateName }}</div>
            </div>
            <div class="col">
              <div class="label">客户姓名</div>
              <div class="value">{{ detail.customerName }}</div>
            </div>
            <div
              class="col"
              v-for="item of detail.reportTelephoneReqVos"
              :key="item.telephone"
            >
              <div class="label">客户电话</div>
              <div class="value">{{ item.telephone | formatPhone }}</div>
            </div>
            <div class="col">
              <div class="label">预计到访</div>
              <div class="value">
                <span v-if="detail.planVisitTime"
                  >{{ detail.planVisitTime }} </span
                >{{ detail.planVisitStr }}
              </div>
            </div>
          </div>
          <div
            class="history-link"
            hover-class="common-hover"
            @click="showHistoryDrawer"
            v-if="customerRecordCount"
          >
            <span>发现该客户{{ customerRecordCount }}条历史记录</span>
            <svg-icon icon-class="right" color="#aaa"></svg-icon>
          </div>
          <div class="info-cell">
            <div class="cell-title">报备信息</div>
            <div class="col">
              <div class="label">报备人姓名</div>
              <div class="value">{{ detail.reportUserName }}</div>
            </div>
            <div class="col">
              <div class="label">报备人电话</div>
              <div class="value">
                {{ detail.reportUserTelephone | formatPhone }}
              </div>
            </div>
            <div class="col span24">
              <div class="label">报备渠道</div>
              <div class="value">{{ detail.company }}</div>
            </div>
            <div class="col span24" v-if="detail.remark">
              <div class="label">备注信息</div>
              <div style="height: 16px;"></div>
              <div
                class="value"
                v-html="detail.remark.replace(/(\n|\r)+/g, '<br>')"
              ></div>
            </div>
          </div>
          <div class="info-cell type2" v-if="detail.reportState === 2">
            <div class="cell-title">
              <span>到访信息</span>
            </div>
            <div class="m-item">
              <div class="label">到访 {{ detail.visitTime }}</div>
            </div>
            <div class="location" v-if="signInInfo.address">
              <i class="iconfont icon-position"></i>
              {{ signInInfo.address
              }}<template v-if="signInInfo && signInInfo.distince"
                >（距离{{
                  signInInfo.distince > 1000
                    ? `${(signInInfo.distince / 1000).toFixed(1)}千米`
                    : `${signInInfo.distince}米`
                }}）</template
              >
            </div>
            <template v-if="visitInfo">
              <template v-if="detail.confirmInfoVo">
                <div class="info-item">
                  <span class="label">带看阶段</span>
                  <span class="value">{{
                    visitInfo.visitNum === 1
                      ? "首看"
                      : visitInfo.visitNum + "看"
                  }}</span>
                </div>
                <div class="info-item">
                  <span class="label">有效判定</span>
                  <span class="value"
                    >{{ visitInfo.valid ? "有效" : "无效" }}带看</span
                  >
                </div>
              </template>
              <template
                v-if="detail.reportState == 5 || detail.reportState == 2"
              >
                <div class="info-item">
                  <span class="label">客户判定</span>
                  <span class="value">{{ visitInfo.starName }}</span>
                </div>
                <div class="info-item">
                  <span class="label">客户类型</span>
                  <span class="value">{{
                    visitInfo.houseType === 2 ? "租赁" : "买卖"
                  }}</span>
                </div>
                <div class="info-item">
                  <span class="label">置业顾问</span>
                  <span class="value">{{ visitInfo.consultantName }}</span>
                </div>
                <div class="info-item type2" v-if="visitInfo.customerDesc">
                  <div class="label">客户白描</div>
                  <div
                    class="value"
                    v-html="visitInfo.customerDesc.replace(/(\n|\r)+/g, '<br>')"
                  ></div>
                </div>
              </template>
              <div class="info-item type2" v-if="visitInfo.visitRemark">
                <div class="label">备注信息</div>
                <div
                  class="value"
                  v-html="visitInfo.visitRemark.replace(/(\n|\r)+/g, '<br>')"
                ></div>
              </div>
              <div class="info-item type2" v-if="visitInfo.images?.length">
                <div class="label">带看凭证</div>
                <div class="value images">
                  <el-image
                    v-for="src of visitInfo.images"
                    :key="src"
                    class="img"
                    :src="src"
                    :preview-src-list="visitInfo.images"
                    width="156"
                    height="156"
                  ></el-image>
                </div>
              </div>
            </template>
          </div>
          <div class="info-cell">
            <div class="cell-title">节点动态</div>
            <c-process
              class="span24"
              :report-id="reportId"
              :data="
                detail.reportProcessNodeVos?.map(item => {
                  return {
                    ...item,
                    operateDate: $time(item.operateDate).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )
                  };
                })
              "
              @change="getReportDetail"
            ></c-process>
          </div>
        </div>
      </div>
    </el-drawer>
    <customer-report-history
      :report-id="reportId"
      :visible.sync="historyVisible"
    ></customer-report-history>
  </div>
</template>

<script>
import CProcess from "./_components/process";
import {
  getReportDetail,
  getReportHistoryCountByCustomer
} from "@/api/distribution";
import CustomerReportHistory from "./_components/customerReportHistory";
export default {
  components: { CProcess, CustomerReportHistory },
  props: {
    reportId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      detail: {},
      historyVisible: false,
      showSkeleton: true,
      customerRecordCount: 0 //客户报备记录
    };
  },
  computed: {
    statusMap() {
      //0-待审核 1-报备成功 2-已带看 3-报备失败 ,
      return {
        0: {
          color: "#fa8c16",
          iconName: "more"
        },
        1: {
          color: "#333333",
          iconName: "clock",
          iconColor: "#999"
        },
        2: {
          color: "#52c41a",
          iconName: "success"
        },
        3: {
          color: "#f5222d",
          iconName: "error"
        },
        4: {
          color: "#fa8c16",
          iconName: "more"
        },
        5: {
          color: "#fa8c16",
          iconName: "more"
        },
        8: {
          color: "#999",
          iconName: "error"
        }
      };
    },
    signInInfo() {
      return this.detail.signInfoVo;
    },
    visitInfo() {
      const {
        determineInfoVo,
        confirmInfoVo,
        processType,
        visitRemark,
        images
      } = this.detail;
      if (processType === 0) {
        return {
          remark: visitRemark,
          images
        };
      }
      return {
        ...determineInfoVo,
        ...(confirmInfoVo || { remark: "" }),
        images: (confirmInfoVo?.attachList || []).map(item => item.fileUrl),
        customerDesc: determineInfoVo?.remark
      };
    }
  },
  watch: {
    async reportId(val) {
      if (!val) return;
      this.getReportHistoryCountByCustomer();
      await this.getReportDetail();
    }
  },
  methods: {
    showHistoryDrawer() {
      this.historyVisible = true;
    },
    closeDrawer() {
      this.$emit("update:visible", false);
    },
    // 获取客户历史报备记录
    async getReportHistoryCountByCustomer() {
      // 项目端才显示记录入口
      const res = await getReportHistoryCountByCustomer({
        reportId: this.reportId
      });
      this.customerRecordCount = res || 0;
    },
    //  获取报备详情
    async getReportDetail() {
      const res = await getReportDetail({ id: this.reportId });
      this.detail = res || {};
    }
  }
};
</script>
<style lang="scss" scoped>
.report-detail {
  width: 400px;
}
.close-drawer-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ccc;
  background: #fff;
  @include flex-xyc;
  &:hover {
    cursor: pointer;
    color: #aaa;
  }
  &:active {
    opacity: 0.8;
  }
}
.action-bar {
  padding: 0 15px;
  height: 50px;
  @include flex-ycb;
  .report-code {
    font-size: 20px;
    font-weight: bold;
  }
  .status {
    padding-right: 40px;
    font-size: 16px;
    @include flex-yc;
    .svg-icon {
      transform: translateY(1px);
    }
  }
  .actions {
    color: #fff;
    display: flex;
    .btn {
      padding: 3px 10px;
      border: 1px solid #eee;
      border-radius: 5px;
      opacity: 0.7;
      @include flex-yc;
      & + .btn {
        margin-left: 10px;
      }
      &:active {
        opacity: 1;
      }
    }
  }
}
.content {
  background: #fff;
  border-radius: 10px 10px 0 0;
  .history-link {
    width: 100%;
    padding: 10px 15px;
    margin: -10px 0 0 0;
    background: #fff;
    border-top: 1px solid #f5f5f5;
    border-bottom: 10px solid #f5f5f5;
    @include flex-b;
    &:hover {
      cursor: pointer;
    }
    &:active {
      color: #999;
    }
  }
  .info-cell {
    padding: 0 15px 15px 15px;
    border-bottom: 10px solid #f5f5f5;
    @include flex-w;
    &.type2 {
      display: block;
      .info-item {
        @include flex-ycb;
        padding: 2px 0;
        &.type2 {
          margin-top: 10px;
          padding-top: 10px;
          border-top: 1px solid #f5f5f5;
          display: block;
          .value {
            margin-top: 4px;
          }
        }
        .label {
          color: #999;
        }
      }
      .m-item {
        padding: 10px 0;
        font-size: 16px;
      }
      .location {
        color: #999;
        margin-bottom: 8px;
      }
    }
    &:last-child {
      border-bottom: 0;
    }
    .cell-title {
      padding-top: 15px;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      @include flex-ycb;
      .link {
        @include flex-yc;
        color: #999;
        font-size: 14px;
        font-weight: normal;
      }
    }
    .col {
      width: 50%;
      .label {
        padding-top: 20px;
        color: #999;
      }
      .value {
        font-size: 15px;
        padding-top: 5px;
      }
    }
    .span24 {
      width: 100%;
    }
    .images {
      gap: 8px;
      @include flex-w;
      ::v-deep {
        .el-image {
          width: 118px;
          height: 118px;
          border: 1px solid #eee;
        }
      }
    }
  }
}
</style>
