<template>
  <el-drawer
    v-bind="$attrs"
    v-on="$listeners"
    :modal="false"
    :with-header="false"
    size="350"
    @open="handleOpen"
  >
    <div class="customer-report-history">
      <div class="head">
        <p>发现该客户{{ historyList.length }}条历史报备</p>
        <svg-icon icon-class="close" @click="closeDrawer"></svg-icon>
      </div>
      <div class="list">
        <div
          class="f-report-item"
          v-for="item of historyList"
          :key="item.id"
          :class="{ invaild: item.reportState === 3 }"
        >
          <div hover-class="common-hover">
            <div class="name-n-time">
              <div class="name">
                {{ item.customerName }}
                <span>{{ item.company }}</span>
              </div>
            </div>
            <div class="info-cell">
              <div>
                进度：<span
                  :style="{ color: statusColorMap[item.reportState] }"
                  >{{ item.reportStateStr }}</span
                >
              </div>
              <div><span>单号：</span>{{ item.reportCode }}</div>
              <div>
                <span>客户：</span>{{ item.customerName }}，{{
                  item.customerTelephone | formatPhone
                }}<i
                  v-if="
                    item.reportTelephoneReqVos &&
                      item.reportTelephoneReqVos.length
                  "
                  >，</i
                >{{
                  item.reportTelephoneReqVos
                    .map(item => item.telephone)
                    .join("，")
                }}
              </div>
              <div v-if="item.reportState === 2">
                <span>带看：{{ item.visitTime }}（{{ item.jetLagStr }}）</span>
              </div>
              <div v-if="item.reportState == 1 || item.reportState === 0">
                <span>报备：{{ item.inDate }}（{{ item.jetLagStr }}）</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { getReportHistoryByCustomer } from "@/api/distribution";
export default {
  props: {
    reportId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      statusColorMap: {
        0: "#fa8c16",
        1: "#333333",
        2: "#52c41a",
        3: "#f5222d"
      },
      historyList: []
    };
  },
  methods: {
    handleOpen() {
      this.getReportHistoryByCustomer();
    },
    closeDrawer() {
      this.$emit("update:visible", false);
    },
    async getReportHistoryByCustomer() {
      const res = await getReportHistoryByCustomer({
        reportId: this.reportId
      });
      if (res) {
        this.historyList = res;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.customer-report-history {
  height: 100%;
  width: 350px;
  background: #f5f5f5;
  @include flex-y;
  .head {
    font-size: 16px;
    padding: 10px;
    @include flex-ycb;
    svg {
      font-size: 14px;
      color: #ccc;
      @include flex-xyc;
      &:hover {
        cursor: pointer;
      }
      &:active {
        opacity: 0.8;
      }
    }
  }
  .list {
    padding: 0 10px;
    flex: 1;
    overflow-y: auto;
  }
  .f-report-item {
    padding: 10px;
    border-radius: 5px;
    background: #ffffff;
    margin-top: 10px;
    &.invaild {
      opacity: 0.5;
      color: #999;
      border-top: 1px solid #ededed;
    }
    .name-n-time {
      @include flex-ycb;
      .name {
        font-size: 16px;
        font-weight: bold;
        span {
          padding-left: 10px;
          font-size: 14px;
          font-weight: normal;
        }
      }
      .time {
        color: #999;
      }
    }
    .sub {
      font-size: 13px;
      margin-top: 10px;
    }
    .info-cell {
      margin-top: 15px;
      color: #666;
      & > div {
        padding: 2px 0;
      }
      text {
        color: #999;
      }
    }
  }
}
</style>
