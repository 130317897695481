var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "process"
  }, [_c('div', {
    staticClass: "list"
  }, _vm._l(_vm.list, function (item, index) {
    return _c('dl', {
      key: index
    }, [_c('dt', [_c('el-image', {
      staticClass: "avatar",
      attrs: {
        "src": item.operateUserAvatar || _vm.$defaultAvatarUrl
      }
    }), item.auditReportReqVo ? [item.auditReportReqVo.reportState === 1 ? _c('div', {
      staticClass: "icon icon-tick"
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": "tick"
      }
    })], 1) : _vm._e(), item.auditReportReqVo.reportState === 3 ? _c('div', {
      staticClass: "icon icon-close"
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": "close"
      }
    })], 1) : _vm._e()] : item.isComplete ? _c('div', {
      staticClass: "icon icon-tick"
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": "tick"
      }
    })], 1) : _c('div', {
      staticClass: "icon icon-more-lite"
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": "more-lite"
      }
    })], 1)], 2), _c('dd', [_c('div', {
      staticClass: "title"
    }, [_c('div', [_vm._v(" " + _vm._s(item.currentNodeStr) + " "), item.currentNode === 'REPORT_AUDIT' && !item.isComplete ? _c('text', {
      staticClass: "red"
    }, [_vm._v(_vm._s(item.operateLabel))]) : _vm._e()]), _c('div', {
      staticClass: "time"
    }, [_vm._v(" " + _vm._s(item.showDate) + " "), item.currentNode === 'REPORT_SUBMIT' ? _c('el-popover', {
      attrs: {
        "placement": "left",
        "title": "修改报备时间",
        "width": "250",
        "trigger": "click"
      },
      model: {
        value: _vm.reportTimeUpdaterVisible,
        callback: function callback($$v) {
          _vm.reportTimeUpdaterVisible = $$v;
        },
        expression: "reportTimeUpdaterVisible"
      }
    }, [_c('el-date-picker', {
      attrs: {
        "type": "datetime",
        "placeholder": "选择日期时间",
        "value-format": "yyyy-MM-dd HH:mm:ss"
      },
      on: {
        "change": _vm.updateReportTime
      },
      model: {
        value: item.operateDate,
        callback: function callback($$v) {
          _vm.$set(item, "operateDate", $$v);
        },
        expression: "item.operateDate"
      }
    }), _c('el-button', {
      staticStyle: {
        "padding": "0 0 0 5px"
      },
      attrs: {
        "slot": "reference",
        "type": "text",
        "size": "small"
      },
      slot: "reference"
    }, [_vm._v("修改")])], 1) : _vm._e()], 1)]), item.currentNode !== 'REPORT_AUDIT' || item.isComplete ? [item.operateUser && item.operateLabel ? _c('p', [_vm._v(" " + _vm._s(item.operateUser) + "，" + _vm._s(item.operateLabel) + " ")]) : _vm._e(), item.extInfo ? _c('p', {
      style: {
        color: item.auditReportReqVo && item.auditReportReqVo.reportState === 3 ? '#333' : '#aaa'
      }
    }, [_vm._v(" " + _vm._s(item.extInfo) + " ")]) : _vm._e()] : _vm._e()], 2)]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }