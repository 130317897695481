var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-drawer', _vm._g(_vm._b({
    attrs: {
      "modal": false,
      "with-header": false,
      "size": "350"
    },
    on: {
      "open": _vm.handleOpen
    }
  }, 'el-drawer', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "customer-report-history"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('p', [_vm._v("发现该客户" + _vm._s(_vm.historyList.length) + "条历史报备")]), _c('svg-icon', {
    attrs: {
      "icon-class": "close"
    },
    on: {
      "click": _vm.closeDrawer
    }
  })], 1), _c('div', {
    staticClass: "list"
  }, _vm._l(_vm.historyList, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "f-report-item",
      class: {
        invaild: item.reportState === 3
      }
    }, [_c('div', {
      attrs: {
        "hover-class": "common-hover"
      }
    }, [_c('div', {
      staticClass: "name-n-time"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(item.customerName) + " "), _c('span', [_vm._v(_vm._s(item.company))])])]), _c('div', {
      staticClass: "info-cell"
    }, [_c('div', [_vm._v(" 进度："), _c('span', {
      style: {
        color: _vm.statusColorMap[item.reportState]
      }
    }, [_vm._v(_vm._s(item.reportStateStr))])]), _c('div', [_c('span', [_vm._v("单号：")]), _vm._v(_vm._s(item.reportCode))]), _c('div', [_c('span', [_vm._v("客户：")]), _vm._v(_vm._s(item.customerName) + "，" + _vm._s(_vm._f("formatPhone")(item.customerTelephone))), item.reportTelephoneReqVos && item.reportTelephoneReqVos.length ? _c('i', [_vm._v("，")]) : _vm._e(), _vm._v(_vm._s(item.reportTelephoneReqVos.map(function (item) {
      return item.telephone;
    }).join("，")) + " ")]), item.reportState === 2 ? _c('div', [_c('span', [_vm._v("带看：" + _vm._s(item.visitTime) + "（" + _vm._s(item.jetLagStr) + "）")])]) : _vm._e(), item.reportState == 1 || item.reportState === 0 ? _c('div', [_c('span', [_vm._v("报备：" + _vm._s(item.inDate) + "（" + _vm._s(item.jetLagStr) + "）")])]) : _vm._e()])])]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }