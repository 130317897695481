var render = function render(){
  var _vm$visitInfo$images, _vm$detail$reportProc;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-drawer', _vm._g(_vm._b({
    attrs: {
      "with-header": false,
      "size": "400"
    }
  }, 'el-drawer', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "report-detail"
  }, [_c('div', {
    staticClass: "action-bar"
  }, [_c('div', {
    staticClass: "report-code"
  }, [_vm._v("NO." + _vm._s(_vm.detail.reportCode))]), _vm.detail.reportState !== undefined ? _c('div', {
    staticClass: "status",
    style: {
      color: _vm.statusMap[_vm.detail.reportState] && _vm.statusMap[_vm.detail.reportState].color
    }
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": _vm.statusMap[_vm.detail.reportState].iconName
    }
  }), _c('span', {
    staticStyle: {
      "padding-left": "5px"
    }
  }, [_vm._v(_vm._s(_vm.detail.reportStateStr))])], 1) : _vm._e(), _c('div', {
    staticClass: "close-drawer-btn",
    on: {
      "click": _vm.closeDrawer
    }
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": "close"
    }
  })], 1)]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "info-cell"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("报备项目")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.detail.estateName))])]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("客户姓名")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.detail.customerName))])]), _vm._l(_vm.detail.reportTelephoneReqVos, function (item) {
    return _c('div', {
      key: item.telephone,
      staticClass: "col"
    }, [_c('div', {
      staticClass: "label"
    }, [_vm._v("客户电话")]), _c('div', {
      staticClass: "value"
    }, [_vm._v(_vm._s(_vm._f("formatPhone")(item.telephone)))])]);
  }), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("预计到访")]), _c('div', {
    staticClass: "value"
  }, [_vm.detail.planVisitTime ? _c('span', [_vm._v(_vm._s(_vm.detail.planVisitTime) + " ")]) : _vm._e(), _vm._v(_vm._s(_vm.detail.planVisitStr) + " ")])])], 2), _vm.customerRecordCount ? _c('div', {
    staticClass: "history-link",
    attrs: {
      "hover-class": "common-hover"
    },
    on: {
      "click": _vm.showHistoryDrawer
    }
  }, [_c('span', [_vm._v("发现该客户" + _vm._s(_vm.customerRecordCount) + "条历史记录")]), _c('svg-icon', {
    attrs: {
      "icon-class": "right",
      "color": "#aaa"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "info-cell"
  }, [_c('div', {
    staticClass: "cell-title"
  }, [_vm._v("报备信息")]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("报备人姓名")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.detail.reportUserName))])]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("报备人电话")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm._f("formatPhone")(_vm.detail.reportUserTelephone)) + " ")])]), _c('div', {
    staticClass: "col span24"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("报备渠道")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.detail.company))])]), _vm.detail.remark ? _c('div', {
    staticClass: "col span24"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("备注信息")]), _c('div', {
    staticStyle: {
      "height": "16px"
    }
  }), _c('div', {
    staticClass: "value",
    domProps: {
      "innerHTML": _vm._s(_vm.detail.remark.replace(/(\n|\r)+/g, '<br>'))
    }
  })]) : _vm._e()]), _vm.detail.reportState === 2 ? _c('div', {
    staticClass: "info-cell type2"
  }, [_c('div', {
    staticClass: "cell-title"
  }, [_c('span', [_vm._v("到访信息")])]), _c('div', {
    staticClass: "m-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("到访 " + _vm._s(_vm.detail.visitTime))])]), _vm.signInInfo.address ? _c('div', {
    staticClass: "location"
  }, [_c('i', {
    staticClass: "iconfont icon-position"
  }), _vm._v(" " + _vm._s(_vm.signInInfo.address)), _vm.signInInfo && _vm.signInInfo.distince ? [_vm._v("（距离" + _vm._s(_vm.signInInfo.distince > 1000 ? "".concat((_vm.signInInfo.distince / 1000).toFixed(1), "\u5343\u7C73") : "".concat(_vm.signInInfo.distince, "\u7C73")) + "）")] : _vm._e()], 2) : _vm._e(), _vm.visitInfo ? [_vm.detail.confirmInfoVo ? [_c('div', {
    staticClass: "info-item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("带看阶段")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.visitInfo.visitNum === 1 ? "首看" : _vm.visitInfo.visitNum + "看"))])]), _c('div', {
    staticClass: "info-item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("有效判定")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.visitInfo.valid ? "有效" : "无效") + "带看")])])] : _vm._e(), _vm.detail.reportState == 5 || _vm.detail.reportState == 2 ? [_c('div', {
    staticClass: "info-item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("客户判定")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.visitInfo.starName))])]), _c('div', {
    staticClass: "info-item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("客户类型")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.visitInfo.houseType === 2 ? "租赁" : "买卖"))])]), _c('div', {
    staticClass: "info-item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("置业顾问")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.visitInfo.consultantName))])]), _vm.visitInfo.customerDesc ? _c('div', {
    staticClass: "info-item type2"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("客户白描")]), _c('div', {
    staticClass: "value",
    domProps: {
      "innerHTML": _vm._s(_vm.visitInfo.customerDesc.replace(/(\n|\r)+/g, '<br>'))
    }
  })]) : _vm._e()] : _vm._e(), _vm.visitInfo.visitRemark ? _c('div', {
    staticClass: "info-item type2"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("备注信息")]), _c('div', {
    staticClass: "value",
    domProps: {
      "innerHTML": _vm._s(_vm.visitInfo.visitRemark.replace(/(\n|\r)+/g, '<br>'))
    }
  })]) : _vm._e(), (_vm$visitInfo$images = _vm.visitInfo.images) !== null && _vm$visitInfo$images !== void 0 && _vm$visitInfo$images.length ? _c('div', {
    staticClass: "info-item type2"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("带看凭证")]), _c('div', {
    staticClass: "value images"
  }, _vm._l(_vm.visitInfo.images, function (src) {
    return _c('el-image', {
      key: src,
      staticClass: "img",
      attrs: {
        "src": src,
        "preview-src-list": _vm.visitInfo.images,
        "width": "156",
        "height": "156"
      }
    });
  }), 1)]) : _vm._e()] : _vm._e()], 2) : _vm._e(), _c('div', {
    staticClass: "info-cell"
  }, [_c('div', {
    staticClass: "cell-title"
  }, [_vm._v("节点动态")]), _c('c-process', {
    staticClass: "span24",
    attrs: {
      "report-id": _vm.reportId,
      "data": (_vm$detail$reportProc = _vm.detail.reportProcessNodeVos) === null || _vm$detail$reportProc === void 0 ? void 0 : _vm$detail$reportProc.map(function (item) {
        return Object.assign({}, item, {
          operateDate: _vm.$time(item.operateDate).format('YYYY-MM-DD HH:mm:ss')
        });
      })
    },
    on: {
      "change": _vm.getReportDetail
    }
  })], 1)])])]), _c('customer-report-history', {
    attrs: {
      "report-id": _vm.reportId,
      "visible": _vm.historyVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.historyVisible = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }